import {
  Image,
  Text,
  Link,
  VStack,
  Button,
  CloseIcon
} from "native-base";

function DressTwoWarranty() {
  var warrantyImage = require('../images/warrentyPage.png');
  return (
    <>
        <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={"4"}>
          <Link href="/anewsweden/idANS0010215">
            <Button variant={'unstyled'}><CloseIcon color={"#1341B6"} size={"4"}/></Button>
          </Link>
        </VStack>
        <VStack alignItems={"center"} marginBottom={"8"}>
          <Text color={"#1341B6"} fontSize={'32px'} fontFamily={'heading'} letterSpacing={'0.46px'}>Warranty Information</Text>
        </VStack>
        <VStack alignItems="flex-start" space={0} px={"4"} marginBottom={"4"}>
          <Text color={"#1341B6"} textTransform={"uppercase"} fontSize={"12px"} fontFamily={'bodyBold'}>purchase date: 16 March 2023</Text>
          <Text color={"#1341B6"} marginBottom={"4"} textTransform={"uppercase"} fontFamily={'bodyBold'} fontSize={'12px'}>warranty VALID UNTIL: 15 March 2038</Text>
          <Text color={"#1341B6"} marginBottom={"8"} fontSize={"12px"}>Your garment is made to last. That's why it has a 15 year warranty. This gives you free repairs for 15 years. You can use our partner's service, Tilli, for repairs and we will cover the cost. </Text>
          <Text color={"#1341B6"} marginBottom={"12"} fontSize={"12px"}>Just go to repairs and make your appointment, then provide us with the invoice by sending it to: <b>help@anewsweden.com</b></Text>
          <Text color={"#1341B6"} fontSize={"15px"} letterSpacing={'2px'} textTransform={"uppercase"} fontFamily={'bodyBold'} marginBottom={'2'}>Returns</Text>
          <Text color={"#1341B6"} marginBottom={"2"} fontSize={"12px"}>This garment had a 30 day return and exchange time period. Unfortunately, that date has passed and you can no longer return or exchange it.</Text>
        </VStack>
        <VStack alignItems={'center'}>
          <Image
              source={{ uri: warrantyImage }}
              alt="Alternate Text"
              size="xl"
              width="full"
              height="auto"
              minHeight={'378px'}
          />
        </VStack>




        </>
  );
}

export default DressTwoWarranty;
