import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.textColor || "#B03D01"};
  border: 1px solid ${(props) => props.borderColor || "#B03D01"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  line-height: 14px;
  font-family: Archivo Regular;
  text-transform: uppercase;
`;

const StyledOutlinedButton = ({
  backgroundColor,
  textColor,
  borderColor,
  width,
  height,
  onClick,
  text,
}) => {
  return (
    <StyledButton
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
      width={width}
      height={height}
      onClick={onClick}
    >
      <ButtonText>{text}</ButtonText>
    </StyledButton>
  );
};

export default StyledOutlinedButton;
