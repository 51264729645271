import {
  Box,
  Image,
  Text,
  Link,
  VStack,
  Button,
  Divider,
  CloseIcon,
  Flex,
  Radio, 
  InfoOutlineIcon
} from "native-base";

function DressTwoResale() {
  var resaleImage = require('../images/dressTwoHome.png');
  return (
    <>
        <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'}>
        <Link href="/anewsweden/idANS0010215">
          <Button variant={'unstyled'}><CloseIcon color={"#1341B6"} size={"4"}/></Button>
          </Link>
        </VStack>
        <VStack alignItems={"center"} marginBottom={"8"}>
          <Text color={"#1341B6"} fontSize={"4xl"} fontFamily={'heading'}>Resale</Text>
        </VStack>
        <VStack alignItems={"center"} space={0} px={"4"} marginBottom={8} textAlign={'center'}>
          <Text color={"#1341B6"} fontSize={"2xl"} alignContent={"center"} fontFamily={'heading'}>
          Interested in selling your Product?
          </Text>
          <Text color={"#1341B6"}>Sell your products with Vestiare Collective</Text>
          <Image
              source={{ uri: resaleImage }}
              alt="Alternate Text"
              size="xl"
              width="333px"
              height="365px"
          />
        </VStack>
        <Flex justifyContent={'space-between'} direction="row" px={'4'} marginBottom={'2'}>
              <Text color={"#1341B6"}>Price you bought it for:</Text>
              <Text color={"#1341B6"}>560 €</Text>
        </Flex>
        <Flex justifyContent={'space-between'} direction="row" px={'4'}>
              <Text color={"#1341B6"}>Date of purchase</Text>
              <Text color={"#1341B6"}>16 March 2023</Text>
        </Flex>
        <VStack alignItems={'center'} mx={'2'} marginTop={'8'} marginBottom={'4'}>
         <Divider/>
        </VStack>
        <VStack alignItems={'flex-start'} px={4} marginBottom={'4'}>
          <Text color="#1341B6" textTransform={'uppercase'}>Resale options <InfoOutlineIcon color="#1341B6" fontSize="xs" height="10px" width="10px"/></Text>
        </VStack>
        <Flex direction="row" px={'4'} justifyContent={'space-between'} alignItems={'center'}>
        <Radio.Group
        colorScheme={{
          borderColor:'#1341B6'
        }}
        display={'flex'} direction={'row'} alignItems={'center'}>
        <Radio mr={'2'}></Radio><Text color={'#1341B6'} fontWeight={'extrabold'} fontFamily={'bodyBold'}>Vestiare <br/>Collective</Text>
          </Radio.Group>
        
        <Text color={"#1341B6"} fontWeight={'extrabold'} fontFamily={'bodyBold'}>Estimated resale price 295 € </Text>
        </Flex>
        <VStack alignItems={'center'} px={4} py={2}>
              <Button variant="outline" width="full" borderColor="#1341B6" _hover={{
            bg:'#1341B6',
            borderColor:'#1341B6',
            _text: { color: '#FFFFFF' },
          }}
          _text={{
            color:"#1341B6",
            fontFamily:"body",
            textTransform:'uppercase'
          }}
          >
          Continue
          </Button>
        </VStack>
        
        </>
  );
}

export default DressTwoResale;
