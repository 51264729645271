import { useEffect, useState,useContext } from "react";
import {
    Image,
    Text,
    Link,
    VStack,
    Button,
    CloseIcon
  } from "native-base";
import { useNavigate,useLocation } from "react-router-dom";
import {getDocument} from '..//utils/firebase/getDoc'
import {filterDataByPage} from '..//utils'
import {COLLECTION,PAGETYPE} from '../constants'
import StyledImage from "../components/Image";
import { AppContext } from '../context';

  function DressThreeCaring() {
    var careImage = require('../images/careImage.png');
    const navigate = useNavigate();
    const [getPageData, setPageData] = useState(null);
    const [getTheme, setTheme] = useState(null);
    const { getData,getParams} = useContext(AppContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Amarezza3 = require("../images/amarezza/Amarezza3.png");

    useEffect(()=>{
      setPageData(filterDataByPage(getData,getParams.styleId,getParams.lang,PAGETYPE.interview))
      setTheme(filterDataByPage(getData,getParams.styleId,getParams.lang))     
    },[getData])
        
    return (
      <>
        {getPageData && (
          <>
            <VStack
              alignItems="flex-start"
              space={0}
              px={"4"}
              paddingTop={"4"}
              marginBottom={"4"}
            >
              {/* <Link href="/la-makanerie/acqua/digitaID/acq0001"> */}
              <Button onPress={() => navigate(-1)} variant={"unstyled"}>
                <CloseIcon color={getTheme?.theme?.color?.primary} size={"4"} />
              </Button>
              {/* </Link> */}
            </VStack>
            <VStack alignItems={"center"} marginBottom={"8"}>
              <Text
                color={getPageData?.title?.styleSheet.color}
                fontSize={getPageData?.title?.styleSheet.size}
                fontFamily={getPageData?.title?.styleSheet.fontFamily}
                letterSpacing={"0.46px"}
              >
                {getPageData?.title?.text}
              </Text>
            </VStack>
            <VStack alignItems={"center"} space={0} px={"4"} marginBottom={8}>
              <Text
                color={getPageData?.desc?.styleSheet.color}
                alignContent={getPageData?.desc?.styleSheet.textAlign}
                fontSize={getPageData?.desc?.styleSheet.size}
                fontFamily={getPageData?.desc?.styleSheet.fontFamily}
                lineHeight={getPageData?.desc?.styleSheet.lineHeight}
              >
                {getPageData?.desc?.text}
              </Text>
            </VStack>
            <VStack alignItems={"flex-start"} px={"4"}>
              <Text
                color={getTheme?.theme?.color?.primary}
                fontSize={getPageData?.secondaryTitle?.styleSheet.size}
                fontFamily={getPageData?.secondaryTitle?.styleSheet.fontFamily}
                letterSpacing={"3px"}
                textTransform={"uppercase"}
              >
                {getPageData?.secondaryTitle?.text}
              </Text>
              <Text
                color={getPageData?.firstSection?.styleSheet.color}
                marginBottom={"8"}
                fontSize={"12px"}
                fontWeight={"400"}
                fontFamily={getPageData?.firstSection?.styleSheet.fontFamily}
              >
                <br></br>
                {getPageData?.firstSection?.text}
                <br></br> <br></br>
                {getPageData?.secondSection?.text}
                <br></br> <br></br>
                {getPageData?.thirdSection?.text}
                <br></br> <br></br>
              </Text>
              <Text>
                <Text
                  color={getTheme?.theme?.color?.primary}
                  marginBottom={"8"}
                  fontSize={"12px"}
                  fontFamily={"archivo_regular"}
                >
                  {getPageData?.ternaryTitle?.text} :
                </Text>{" "}
                <br></br>
              </Text>
              <Text
                color={
                  getPageData?.ternaryTitle?.sections.firstSection.styleSheet
                    .color
                }
                marginBottom={"8"}
                fontSize={
                  getPageData?.ternaryTitle?.sections.firstSection.styleSheet
                    .size
                }
                fontFamily={
                  getPageData?.ternaryTitle?.sections.firstSection.styleSheet
                    .fontFamily
                }
                fontWeight={"400"}
              >
                {getPageData?.ternaryTitle?.sections.firstSection.text}{" "}
                <br></br>
                <br></br>
                {getPageData?.ternaryTitle?.sections.secondSection.text}{" "}
                <br></br>
                <br></br>
                {getPageData?.ternaryTitle?.sections.thirdSection.text}
              </Text>
            </VStack>
            <VStack alignItems={"center"} marginBottom={5}>
              <StyledImage
                width="60%"
                src={
                  getParams.style === "amarezza" ? Amarezza3 : getPageData.images?.primary
                }
                alt={"Image of the product design 2"}
              />
            </VStack>
          </>
        )}
      </>
    );
  }
  
  export default DressThreeCaring;
  