import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  line-height: 14px;
  font-family: Archivo Regular;
  color: #000;
`;

const SecondaryText = styled.p`
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  line-height: 14px;
  font-family: Archivo Regular;
  color: #000;
`;

const SecondaryWrapper = styled.div`
  margin-top: 10px;
`;

const Error = ({ errorTitle,errorMessage }) => {
  return (
    <MainWrapper>
      <StyledText>{errorTitle}</StyledText>
      <SecondaryWrapper>
      <SecondaryText>{errorMessage}</SecondaryText>
      </SecondaryWrapper>
    </MainWrapper>
  );
};

export default Error;
