import {
    Box,
    Image,
    Text,
    Link,
    VStack,
    Button,
    Divider,
    CloseIcon,
    Flex,
    Radio, 
    InfoOutlineIcon
  } from "native-base";
  
  function DressOneResale() {
    var resaleImage = require('../images/dressOneResale.png');
    return (
      <>
          <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'}>
          <Link href="/anewsweden/idANS0030567">
            <Button variant={'unstyled'}><CloseIcon color={"primary.600"} size={"4"}/></Button>
            </Link>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"8"}>
            <Text color={"primary.600"} fontSize={"4xl"} fontFamily={'heading'}>Resale</Text>
          </VStack>
          <VStack alignItems={"center"} space={0} px={"4"} marginBottom={8} textAlign={'center'}>
            <Text color={"primary.600"} fontSize={"2xl"} alignContent={"center"} fontFamily={'heading'}>
            Interested in selling your Product?
            </Text>
            <Text color={"primary.600"}>Sell your products with Vestiare Collective</Text>
            <Image
                source={{ uri: resaleImage }}
                alt="Alternate Text"
                size="xl"
                width="262px"
                height="365px"
            />
          </VStack>
          <Flex justifyContent={'space-between'} direction="row" px={'4'} marginBottom={'2'}>
                <Text color={"primary.600"}>Purchase price</Text>
                <Text color={"primary.600"}>340 €</Text>
          </Flex>
          <Flex justifyContent={'space-between'} direction="row" px={'4'}>
                <Text color={"primary.600"}>Date of purchase</Text>
                <Text color={"primary.600"}>16 March 2023</Text>
          </Flex>
          <VStack alignItems={'center'} mx={'2'} marginTop={'8'} marginBottom={'4'}>
           <Divider/>
          </VStack>
          <VStack alignItems={'flex-start'} px={4} marginBottom={'4'}>
            <Text color="primary.600" textTransform={'uppercase'}>Resale options <InfoOutlineIcon color="primary.600" fontSize="xs" height="10px" width="10px"/></Text>
          </VStack>
          <Flex direction="row" px={'4'} justifyContent={'space-between'} alignItems={'center'}>
          <Radio.Group display={'flex'} direction={'row'} alignItems={'center'}>
          <Radio mr={'2'}></Radio><Text color={'primary.600'} fontWeight={'extrabold'} fontFamily={'bodyBold'}>Vestiare <br/>Collective</Text>
            </Radio.Group>
          
          <Text color={"primary.600"} fontWeight={'extrabold'} fontFamily={'bodyBold'}>Estimated resale price 195 € </Text>
          </Flex>
          <VStack alignItems={'center'} px={4}>
          <Button variant="solid" colorScheme="primary" width="full" my={4}>
          Continue
          </Button>
          </VStack>
          
          </>
    );
  }
  
  export default DressOneResale;
  