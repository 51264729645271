import {
  Image,
  Text,
  Link,
  VStack,
  Button,
  ArrowForwardIcon,
  InfoOutlineIcon,
} from "native-base";
import logo from '../images/anewswedenlogo.svg'
import dressTwo from '../images/dressTwoHome.png'

function DressTwoHome() {
  
  var dressImage = require("../images/dressTwoHome.png");
  var banner = require("../images/homeBanner.png");

  const hoverAttrs= {
      bg: "transparent",
      borderColor: "#1341B6"
  }

  return (
    <>
        <VStack alignItems="center" space={2} py={'4'} paddingTop={'30px'}>
          <Image
            source={{ uri: logo }}
            alt="Alternate Text"
            width="113px"
            height="23px"
          />
        </VStack>
        <VStack alignItems="center" space={2} px={"4"}>
          <Image
            source={{ uri: dressImage }}
            alt="Alternate Text"
            height="398px"
            width="381px"
          />
        </VStack>
        <VStack alignItems={'center'} space={'3'}>
          <Text color="#1341B6" fontSize="xs">
            This product is authenticated <InfoOutlineIcon color="#1341B6" fontSize="xs" height="10px" width="10px"/>
          </Text>
          <Text color="#1341B6" fontSize="4xl" alignContent="center" fontFamily={'heading'} letterSpacing={'md'}>
          Findor Wool Field Jacket
          </Text>
          <Text color="#1341B6" fontSize="sm" marginBottom={6}>
          Digital ID: ANS0010215
          </Text>
          <Image
            source={{ uri: banner }}
            size="xl"
            width="full"
            height="246px"
            marginBottom={'6'}
          />
        </VStack>

        <VStack alignItems="flex-start" px={4} py={4} space={4}>
          <Text color="#1341B6" fontSize="3xl" alignContent="flex-start" fontFamily={'heading'} letterSpacing={'lg'}>
            Proudly made in Sweden
          </Text>
          <Text color="#1341B6" fontFamily={'body'}>
            To keep our supply chain short, we manufacture in Sweden. our
            network of passionate ateliers in Malmö and Ljungskile are committed
            to producing the finest quality garment for us.
          </Text>
          <Link href="/anewsweden/idANS0010215/SupplyChain" width={'full'}>
            <Text color="#1341B6" py={4} fontFamily={'body'} textTransform={'uppercase'}>see the entire Supply Chain <ArrowForwardIcon color="#1341B6"/></Text>
          </Link>
        </VStack>

        <VStack alignItems="center" space={2} marginBottom={7} mx={4}>
        <Link href="/anewsweden/idANS0010215/Warranty" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6" fontFamily={'heading'}
          _hover={hoverAttrs}
          >
              <Text
              color="#1341B6" fontFamily={'body'} textTransform={'uppercase'}
              >Warranty information</Text>
          </Button>
          </Link>
          <Link href="/anewsweden/idANS0010215/care" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6" _hover={hoverAttrs}>
            <Text color="#1341B6" fontFamily={'body'} textTransform={'uppercase'}>Caring for your garment</Text>
          </Button>
          </Link>
        </VStack>
        <VStack alignItems="center" space={2} mx={4} py={2}>
        <Link href="/anewsweden/idANS0010215/repair" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6" _hover={hoverAttrs}>
          <Text color="#1341B6" fontFamily={'body'} textTransform={'uppercase'}>Repair</Text>
          </Button>
          </Link>
          <Link href="/anewsweden/idANS0010215/resale" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6" _hover={hoverAttrs}>
          <Text color="#1341B6" fontFamily={'body'} textTransform={'uppercase'}>Sell</Text>
          </Button>
          </Link>
          <Link href="/anewsweden/idANS0010215/endoflife" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6"
            _hover={hoverAttrs}>
          <Text color="#1341B6" fontFamily={'body'} textTransform={'uppercase'}>Upcycle</Text>
          </Button>
          </Link>

          <Link href="mailto:help@anewsweden.com" width={'full'}>
          <Button variant="outline" width="full" borderColor="#1341B6" _hover={{
            bg:'#1341B6',
            borderColor:'#1341B6',
            _text: { color: '#FFFFFF' },
          }}
          _text={{
            color:"#1341B6",
            fontFamily:"body",
            textTransform:'uppercase'
          }}
          >
          Customer Assistance  
          </Button>
          </Link>
        </VStack>
        </>
  );
}

export default DressTwoHome;
