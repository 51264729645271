import React from "react";
import styled from "styled-components";
import { DESKTOPSIZE } from "../../constants";

const Container = styled.div`
  position: relative;
  width: ${(props) => props.width || "100%"};
  max-width: 100%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

function StyledImage({ src, alt, width = null }) {
  let imageWidth = "100%";
  if (window.innerWidth > DESKTOPSIZE && width) {
    imageWidth = width;
  }
  return (
    <Container width={imageWidth}>
      <Image src={src} alt={alt} />
    </Container>
  );
}

export default StyledImage;
