import { useEffect, useContext, useState } from "react";

import { Image, Text, Link, VStack, Button, CloseIcon } from "native-base";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getDocument } from "..//utils/firebase/getDoc";
import { filterDataByPage } from "..//utils";
import StyledButton from "../components/Button";
import { COLLECTION, PAGETYPE } from "../constants";
import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'
import { AppContext } from '../context';

function Return() {
  const navigate = useNavigate();
  const [getPageData, setPageData] = useState(null);
  const [getTheme, setTheme] = useState(null);
  const { getData,getParams} = useContext(AppContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Acv = require("../images/amarezza/Acv.png");

  const style = queryParams.get("style");
  useEffect(() => {
    dispatchAnalyticsPageView(location.pathname,PAGETYPE.return)
    setPageData(filterDataByPage(getData, getParams.styleId, getParams.lang, PAGETYPE.warranty));
    setTheme(filterDataByPage(getData, getParams.styleId, getParams.lang));
  }, [getData]);
  return (
    <>
      {getPageData && (
        <>
          <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={"4"}>
            <Button onPress={() => navigate(-1)} variant={"unstyled"}>
              <CloseIcon color={getTheme?.theme?.color?.primary} size={"4"} />
            </Button>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"8"}>
            <Text
              color={getPageData.title.styleSheet.color}
              fontSize={getPageData.title.styleSheet.size}
              fontFamily={getPageData.title.styleSheet.fontFamily}
              letterSpacing={"0.46px"}
              lineHeight={getPageData.title.styleSheet.lineHeight}
              textAlign={getPageData.title.styleSheet.textAlign}
            >
              {getPageData.title?.text}
            </Text>
          </VStack>
          <VStack alignItems="flex-start" space={0} px={"4"} >
            <Text
              color={getTheme?.theme?.color?.primary}
              fontSize={getPageData.title.secondaryTitle.styleSheet.size}
              fontFamily={
                getPageData.title.secondaryTitle.styleSheet.fontFamily
              }
              letterSpacing={"2px"}
              textTransform={"uppercase"}
              marginBottom={"2"}
            >
              {getPageData.title.secondaryTitle?.text}
            </Text>
            <Text
              marginBottom={"2"}
              color={getPageData.title.secondaryDesc.styleSheet.color}
              fontSize={getPageData.title.secondaryDesc.styleSheet.size}
              fontFamily={getPageData.title.secondaryDesc.styleSheet.fontFamily}
            >
              {getPageData.title.secondaryDesc?.text}
            </Text>
            </VStack>
            <VStack alignItems="flex-start" px={4}>
            <Link
              href={`https://www.la-makanerie.com/conditions-g%C3%A9n%C3%A9rales-de-ventes`}
              width={"full"}
            >
              <Text
                color=""
                py={4}
                textTransform={"uppercase"}
                fontFamily={"archivo_regular"}
                fontSize="12px"
              >
                En Savoir plus ici →
              </Text>
            </Link>
          </VStack>
            <VStack alignItems="flex-start" space={0} px={"4"} marginBottom={"4"}>
            <Text
              color={getTheme?.theme?.color?.primary}
              fontWeight={getPageData.title.ternaryTitle.styleSheet.fontWeight}
              fontSize={getPageData.title.ternaryTitle.styleSheet.size}
              fontFamily={getPageData.title.ternaryTitle.styleSheet.fontFamily}
              letterSpacing={
                getPageData.title.ternaryTitle.styleSheet.letterSpacing
              }
              textTransform={"uppercase"}
              marginBottom={"2"}
            >
              {getPageData.title.ternaryTitle?.text}
            </Text>
            <Text marginBottom={"2"}
            fontWeight={getPageData.title.ternaryDesc?.styleSheet.fontWeight}
            fontSize={getPageData.title.ternaryDesc?.styleSheet.size} fontFamily={getPageData.title.ternaryDesc?.styleSheet.fontFamily}>
              {getPageData.title.ternaryDesc?.text}
            </Text>
            <Link
              href="https://tilli.fr/reserver-votre-couturier-a-domicile/"
              width={"full"}
            >
              <StyledButton
                text={getPageData.title.actionBtn?.text}
                backgroundColor={getTheme?.theme?.color?.primary}
                textColor="#FFFFFF"
                borderColor="transparent"
                width="100%"
                height="54px"
                hoverBackgroundColor="#FFFFFF"
                hoverTextColor={getTheme?.theme?.color?.primary}
                hoverBorderColor={getTheme?.theme?.color?.primary}
              />
            </Link>
          </VStack>
          <VStack alignItems={"center"}>
            <Image
              source={{
                uri: style === "amarezza" ? Acv : getPageData.images?.primary,
              }}
              alt="Alternate Text"
              size="xl"
              width="full"
              height="auto"
              minHeight={"378px"}
            />
          </VStack>
        </>
      )}
    </>
  );
}

export default Return;
