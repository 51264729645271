import React from "react";
import {
    Box,
    Image,
    Text,
    Link,
    VStack,
    Button,
    Select,
    CloseIcon,
    Flex
  } from "native-base";
  
  function DressOneRepair() {
    var manufacImage = require('../images/manufac.png');
    const [service, setService] = React.useState("");
    return (
      <>
      <Flex minH={'100vh'} direction="column" justifyContent={'space-between'}>
        <Box>
          <VStack alignItems="flex-start" space={4} px={"4"} paddingTop={'4'}>
            <Link href="/anewsweden/idANS0030567">
              <Button variant={'unstyled'}><CloseIcon color={"primary.600"} size={"5"}/></Button>
            </Link>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"4"} px={4} space={'6'}>
            <Text color={"primary.600"} fontFamily={'heading'} fontSize={'4xl'} letterSpacing={'0.46px'} >Repairs</Text>
            <Text color={"primary.600"} fontSize={'2xl'} fontFamily={'heading'}>Do you need to repair or alter your dress?</Text>
          </VStack>
          <Box px={'4'} marginBottom={'6'}>
          <Select selectedValue={service} placeholder="Select repair or alter" accessibilityLabel="Select option" width={'full'} padding={'4'} onValueChange={itemValue => setService(itemValue)} _selectedItem={{
        bg: "black"
      }}>
          <Select.Item label="Repair" value="repair"/>
          <Select.Item label="Alter" value="alter"/>
          </Select>
          </Box>
          <VStack alignItems={"center"} px={4} space={'4'}>
            <Text color={'primary.600'} fontSize={'14px'}>Through our partner, Tilli, we are able to make it convenient for you to repair or alter your dress.</Text>
            <Text color={'primary.600'} fontSize={'14px'}>Just click below and go to Tilli’s site to make an appointment for a tailor to help you.</Text>  
            <Link href="https://tilli.fr/reserver-votre-couturier-a-domicile/" width={'full'}>
            <Button variant={'solid'} width="full" colorScheme={'primary'} my={4}>
            Go to Repair
          </Button>
          </Link>
          </VStack>
          </Box>
          <VStack alignItems={'center'} marginTop={4}>
          <Image
                source={{ uri: manufacImage}}
                alt="Alternate Text"
                size="xl"
                width="full"
                height="411px"
            />
          </VStack>
          </Flex>

          </>
    );
  }
  
  export default DressOneRepair;
  