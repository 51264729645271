import {
  Image,
  Text,
  Link,
  VStack,
  Button,
  ArrowForwardIcon,
  InfoOutlineIcon,
} from "native-base";
import logo from "../images/anewswedenlogo.svg";
import dressOne from "../images/dressOneSvg.svg";
import ReactGA from "react-ga4";

function DressOneHome() {
  var dressImage = require("../images/dressImage.png");
  var banner = require("../images/homeBanner.png");

  const dispatchEvent = (type) => {
    ReactGA.event({
      category: "dev",
      action: "select",
      label: type, // optional
    });
  };

  return (
    <>
      <VStack alignItems="center" space={2} py={"4"} paddingTop={"30px"}>
        <Image
          source={{ uri: logo }}
          alt="Alternate Text"
          width="113px"
          height="23px"
        />
      </VStack>
      <VStack alignItems="center" space={2} px={"4"}>
        <Image
          source={{ uri: dressOne }}
          alt="Alternate Text"
          height="398px"
          width="381px"
        />
      </VStack>
      <VStack alignItems={"center"} space={"3"}>
        <Text color="primary.600" fontSize="xs">
          This product is authenticated{" "}
          <InfoOutlineIcon
            color="primary.600"
            fontSize="xs"
            height="10px"
            width="10px"
          />
        </Text>
        <Text
          color="primary.600"
          fontSize="4xl"
          alignContent="center"
          fontFamily={"heading"}
          letterSpacing={"md"}
        >
          Jämtland Wool Jersey Dress
        </Text>
        <Text color="primary.600" fontSize="sm" marginBottom={6}>
          Digital ID: ANS0030567
        </Text>
        <Image
          source={{ uri: banner }}
          size="xl"
          width="full"
          height="246px"
          marginBottom={"6"}
        />
      </VStack>

      <VStack alignItems="flex-start" px={4} py={4} space={4}>
        <Text
          color="primary.600"
          fontSize="3xl"
          alignContent="flex-start"
          fontFamily={"heading"}
          letterSpacing={"lg"}
        >
          Proudly made in Sweden
        </Text>
        <Text color="primary.600" fontFamily={"body"}>
          To keep our supply chain short, we manufacture in Sweden. our network
          of passionate ateliers in Malmö and Ljungskile are committed to
          producing the finest quality garment for us.
        </Text>
        <Link href="/anewsweden/idANS0030567/SupplyChain" width={"full"}>
          <Text
            color="primary.600"
            py={4}
            fontFamily={"body"}
            textTransform={"uppercase"}
          >
            see the entire Supply Chain <ArrowForwardIcon color="primary.600" />
          </Text>
        </Link>
      </VStack>

      <VStack alignItems="center" space={2} marginBottom={7} mx={4}>
        <Link href="/anewsweden/idANS0030567/Warranty" width={"full"}>
          <Button
            onPress={dispatchEvent("Warranty_Information")}
            variant="outline"
            width="full"
            borderColor="primary.600"
            fontFamily={"heading"}
          >
            Warranty information
          </Button>
        </Link>
        <Link href="/anewsweden/idANS0030567/care" width={"full"}>
          <Button
            variant="outline"
            width="full"
            borderColor="primary.600"
            onPress={dispatchEvent("Caring_For_Your_Garment")}
          >
            Caring for your garment
          </Button>
        </Link>
      </VStack>
      <VStack alignItems="center" space={2} mx={4}>
        <Link href="/anewsweden/idANS0030567/repair" width={"full"}>
          <Button
            variant="outline"
            width="full"
            borderColor="primary.600"
            onPress={dispatchEvent("Repair")}
          >
            Repair
          </Button>
        </Link>
        <Link href="/anewsweden/idANS0030567/resale" width={"full"}>
          <Button
            variant="outline"
            width="full"
            borderColor="primary.600"
            onPress={dispatchEvent("Sell")}
          >
            sell
          </Button>
        </Link>
        <Link href="/anewsweden/idANS0030567/endoflife" width={"full"}>
          <Button
            variant="outline"
            width="full"
            borderColor="primary.600"
            onPress={dispatchEvent("Upcycle")}
          >
            Upcycle
          </Button>
        </Link>
        <Link href="mailto:help@anewsweden.com" width={"full"}>
          <Button
            variant="solid"
            colorScheme="primary"
            width="full"
            my={4}
            onPress={dispatchEvent("Customer_Assistance")}
          >
            Customer Assistance
          </Button>
        </Link>
      </VStack>
    </>
  );
}

export default DressOneHome;
