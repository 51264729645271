import {useEffect} from 'react'
import { Image, Text, Link, VStack, Button, CloseIcon } from "native-base";
import "react-vertical-timeline-component/style.min.css";
import SourceIcon from "../images/sourceIcon.svg";
import bioDegradable from "../images/bioDegradable.svg";
import pallete from "../images/pallete.png";
import { useLocation } from 'react-router-dom';
import { ACTIONS, COLLECTION, EVENTS, PAGETYPE,PAGE_NAVIGATION } from "../constants";
import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'

function FabricTemplate(props) {
  const primaryColor = props.primaryColor;
  const location = useLocation();

  useEffect(()=>{
    dispatchAnalyticsPageView(location.pathname,PAGETYPE.fabric_information)
  },[])

  return (
    <div>
      <VStack alignItems="flex-start" space={0} px={"4"}>
        <Text
          color={"#000000"}
          fontSize={"24px"}
          lineHeight={'26px'}
          letterSpacing={"2px"}
          fontFamily={"archivo_regular"}
          marginBottom={"2"}
          fontWeight={'400'}
          textAlign={'center'}
        >
          Soie
        </Text>
        <Text color={"#000000"} marginBottom={"8"} 
        fontSize={"12px"}
        fontWeight={'100'}
        fontFamily={'archivo_regular'}
        >
          Bien que l’industrie européenne occupait autrefois une part importante
          du marché, elle s’est aujourd’hui réduite et a permis à la soie
          asiatique traditionnelle de devenir plus répandue. Notre soie provient
          de producteurs de soie chinois, qui respectent les critères
          environnementaux et sociaux les plus stricts.
        </Text>

        <Text
          marginBottom={"8"} 
         fontSize={"15px"}
         fontWeight={'400'}
         fontFamily={'archivo_regular'}
         color={primaryColor}
         lineHeight={'13px'}
         textTransform={'uppercase'}
        >
          LA Source
        </Text>
      </VStack>
      <VStack alignItems="center" py={"4"}>
        <Image
          source={{ uri: SourceIcon }}
          alt="Alternate Text"
          width="50px"
          height="50px"
        />
      </VStack>
      <VStack alignItems="flex-start" space={0} px={"4"} marginBottom={"4"}>
        <Text 
        color={"#000000"} marginBottom={"8"} 
        fontSize={"12px"}
        fontWeight={'100'}
        fontFamily={'archivo_regular'}
        >
          La soie est une fibre naturelle issue du ver à soie. Lors de leur
          transformation de larve en papillon, les vers à soie s'enroulent dans
          un cocon de soie. Une fois le cocon entièrement formé, il est bouilli
          et les fibres de soie sont récoltées. Le matériau obtenu et les
          produits crées à partir de celui-ci sont solides et durables.
        </Text>
      </VStack>

      <VStack alignItems="flex-start" space={0} px={"4"}>
        <Text
           marginBottom={"8"} 
           fontSize={"15px"}
           fontWeight={'400'}
           fontFamily={'archivo_regular'}
           color={primaryColor}
           lineHeight={'13px'}
           textTransform={'uppercase'}
        >
          LA Durabilité
        </Text>
      </VStack>
      <VStack alignItems="center" space={0} px={"4"} py={'5'}>
      <Image
          source={{ uri: pallete }}
          alt="Alternate Text"
          width="255px"
          height="65px"
        />
      </VStack>

      <VStack alignItems="flex-start" space={0} px={"4"} marginBottom={"4"}>
        <Text
        color={"#000000"} marginBottom={"8"} 
        fontSize={"12px"}
        fontWeight={'100'}
        fontFamily={'archivo_regular'}
        >
          Malgré la délicatesse de la soie, elle reste à ce jour l’un des
          textiles les plus durables.
        </Text>
      </VStack>

      <VStack alignItems="flex-start" space={0} px={"4"}>
        <Text
          marginBottom={"8"} 
          fontSize={"15px"}
          fontWeight={'400'}
          fontFamily={'archivo_regular'}
          color={primaryColor}
          lineHeight={'13px'}
          textTransform={'uppercase'}
        >
          Biodegradabilité
        </Text>
      </VStack>

      <VStack alignItems="center" py={"4"}>
        <Image
          source={{ uri: bioDegradable }}
          alt="Alternate Text"
          width="50px"
          height="50px"
        />
      </VStack>

      <VStack alignItems="flex-start" space={0} px={"4"} marginBottom={"4"}>
        <Text
        color={"#000000"} marginBottom={"8"} 
        fontSize={"12px"}
        fontWeight={'100'}
        fontFamily={'archivo_regular'}
        >
          Dans de bonnes conditions, la soie conventionnelle met entre un à deux
          ans pour se dégrader complètement dans l’environnement. Le fait qu’un
          produit se dégrade rapidement n’est pas nécessairement une bonne
          nouvelle pour l’écosystème.  La soie utilisée pour fabriquer nos
          produits a été produite dans des conditions strictes qui interdisent
          l'utilisation de produits chimiques toxiques et de métaux lourds tout
          au long de sa chaîne de production : de l'élevage des vers à la
          récolte des fibres, en passant par le blanchiment et la teinture. Cela
          lui permet de se biodégrader et de ne causer pratiquement aucun
          dommage à l’environnement.
        </Text>
      </VStack>
    </div>
  );
}

export default FabricTemplate;
