import { Image, Text, Link, VStack, Button, CloseIcon } from "native-base";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./dressOneSupplyChain.css";

function DressOneSupplyChain() {
  var imageOne = require("../images/dressOneTimeLineImgOne.png");
  var imageTwo = require("../images/dressOneTimeLineImgTwo.png");
  var imageThree = require("../images/dressOneTimeLineImgThree.png");
  var imageFour = require("../images/dressOneTimeLineImgFour.png");
  return (
    <>
      <VStack
        alignItems="flex-start"
        space={0}
        px={"4"}
        paddingTop={"4"}
        marginBottom={"2"}
      >
        <Link href="/anewsweden/idANS0030567">
          <Button variant={"unstyled"}>
            <CloseIcon color={"primary.600"} size={"4"} />
          </Button>
        </Link>
      </VStack>
      <VStack
        alignItems={"center"}
        marginBottom={"6"}
        textAlign={"center"}
        paddingTop={"8"}
      >
        <Text
          color={"primary.600"}
          fontSize={"4xl"}
          fontFamily={"heading"}
          lineHeight={"30px"}
        >
          Your Garment’s <br />
          Provenance
        </Text>
      </VStack>
      <VStack alignItems={"center"} space={2}>
        <Text color={"primary.600"} fontSize={"10px"} letterSpacing={"0.46px"}>
          7,498 km traveled to you
        </Text>
        <Text color={"primary.600"} fontSize={"10px"} letterSpacing={"0.46px"}>
          (avg. is 26,800 km)
        </Text>
      </VStack>
      <VerticalTimeline
        lineColor="red"
        animate="false"
        layout="1-column-left"
        className="timeLineCustom2"
      >
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ boxShadow: "none" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#fff",
            color: "black",
            border: "1px solid #1341B6",
            boxShadow: "none",
            padding: "3px",
            height: "60px",
            width: "60px",
          }}
          icon={
            <Image
              source={{ uri: imageOne }}
              height={"full"}
              borderRadius={"50%"}
            />
          }
        >
          <VStack space={4}>
            <Text
              fontFamily={"heading"}
              color={"primary.600"}
              fontSize={"2xl"}
              letterSpacing={"0.46px"}
            >
              Fibre
            </Text>
            <Text color={"primary.600"} fontSize={"12px"}>
              The wool is sourced from 3 different Jämtland wool farms in
              northern Sweden using organic feed and processes.
            </Text>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Winterlia Farm
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Laxviken, Sweden
              </Text>
            </VStack>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Rudsby jord & Skog
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Lit, Sweden
              </Text>
            </VStack>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Winterlia Farm
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Laxviken, Sweden
              </Text>
            </VStack>
          </VStack>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ boxShadow: "none" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#fff",
            color: "black",
            border: "1px solid #1341B6",
            boxShadow: "none",
            padding: "3px",
            height: "60px",
            width: "60px",
          }}
          icon={
            <Image
              source={{ uri: imageTwo }}
              height={"full"}
              borderRadius={"50%"}
            />
          }
        >
          <VStack space={4}>
            <Text
              fontFamily={"heading"}
              color={"primary.600"}
              fontSize={"2xl"}
              letterSpacing={"0.46px"}
            >
              Fibre Processing
            </Text>
            <Text color={"primary.600"} fontSize={"12px"}>
              As there are no longer any places to process the wool in Sweden,
              where it can be sorted, cleaned and spun, the spinner was
              carefully selected in Italy. The spinner is a British transport to
              Italy, who buys the wool directly from our farms.
            </Text>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                biella wool consortium
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Biella, italy
              </Text>
            </VStack>
          </VStack>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ boxShadow: "none" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#fff",
            color: "black",
            border: "1px solid #1341B6",
            boxShadow: "none",
            padding: "3px",
            height: "60px",
            width: "60px",
          }}
          icon={
            <Image
              source={{ uri: imageThree }}
              height={"full"}
              borderRadius={"50%"}
            />
          }
        >
          <VStack space={4}>
            <Text
              fontFamily={"heading"}
              color={"primary.600"}
              fontSize={"2xl"}
              letterSpacing={"0.46px"}
            >
              Fabric
            </Text>
            <Text color={"primary.600"} fontSize={"12px"}>
              The Jämtland wool is a heavier wool that the Swedish knitting
              machines are not able to handle, so across the channel in Denmark,
              the wool yarn is spun in a family-owned knitting factory.{" "}
            </Text>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                sontex
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                ikast, denmark
              </Text>
            </VStack>
          </VStack>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="timeLineElementLast"
          contentStyle={{ boxShadow: "none" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#fff",
            color: "black",
            border: "1px solid #1341B6",
            boxShadow: "none",
            padding: "3px",
            height: "60px",
            width: "60px",
          }}
          icon={
            <Image
              source={{ uri: imageFour }}
              height={"full"}
              borderRadius={"50%"}
            />
          }
        >
          <VStack space={4}>
            <Text
              fontFamily={"heading"}
              color={"primary.600"}
              fontSize={"2xl"}
              letterSpacing={"0.46px"}
            >
              Cutting and Sewing
            </Text>
            <Text color={"primary.600"} fontSize={"12px"}>
              Your garment was cut and sewn in a historic jersey mill, since
              1935, with a responsible and sustainable production in mind.{" "}
            </Text>

            <VStack space={2}>
              <Text
                fontFamily={"bodyBold"}
                color={"primary.600"}
                fontSize={"14px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Ljungskile trikå
              </Text>
              <Text
                color={"primary.600"}
                fontSize={"10px"}
                textTransform={"uppercase"}
                letterSpacing={"0.46px"}
              >
                Ljungskile, sweden
              </Text>
            </VStack>
          </VStack>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <VStack px={4}>
        <Link href="https://www.anewsweden.com/blogs/suppliers" width={"full"}>
          <Button variant="solid" colorScheme="primary" width="full" my={4}>
            Learn More
          </Button>
        </Link>
      </VStack>
    </>
  );
}

export default DressOneSupplyChain;
