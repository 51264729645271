import React from 'react';
import styled from 'styled-components';
import PropagateLoader from "react-spinners/PropagateLoader";

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  line-height: 14px;
  font-family: Archivo Regular;
  text-transform: uppercase;
  color: #000;
`;

const LoaderWrapper = styled.div`
  margin-top: 10px;
`;

const Loader = () => {
  return (
    <MainWrapper>
      <StyledText>MĀDI</StyledText>
      <LoaderWrapper>
        <PropagateLoader color="#000" />
      </LoaderWrapper>
    </MainWrapper>
  );
};

export default Loader;
