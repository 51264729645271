import React from 'react';
import styled from 'styled-components';

const CustomButton = styled.button`
  background-color: ${props => props.backgroundColor || '#B03D01'};
  color: ${props => props.textColor || '#FFFFFF'};
  border: 1px solid ${props => props.borderColor || 'transparent'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  cursor:pointer;

  &:hover {
    background-color: ${props => props.hoverBackgroundColor || '#FFFFFF'};
    color: ${props => props.hoverTextColor || '#B03D01'};
    border-color: ${props => props.hoverBorderColor || '#B03D01'};
  }
`;

const ButtonText = styled.span`
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  line-height: 14px;
  font-family:Archivo Regular;
  text-transform:uppercase
`;

const StyledButton = ({
  text,
  onClick,
  backgroundColor,
  textColor,
  borderColor,
  width,
  height,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorderColor,
}) => {
  return (
    <CustomButton
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
      width={width}
      height={height}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverTextColor={hoverTextColor}
      hoverBorderColor={hoverBorderColor}
      onClick={onClick}
    >
      <ButtonText>{text}</ButtonText>
    </CustomButton>
  );
};

export default StyledButton;
