import { useState, useEffect,useContext } from "react";
import {
  Image,
  Text,
  Link,
  VStack,
  Button,
  CloseIcon,
  Radio,
} from "native-base";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./dressThreeSupplyChain.css";
import { useNavigate } from "react-router-dom";
import FabricTemplate from "../templates/fabrics";
import { useLocation } from "react-router-dom";
import { getDocument } from "..//utils/firebase/getDoc";
import { filterDataByPage } from "..//utils";
import StyledButton from "../components/Button";
import { ACTIONS, COLLECTION, EVENTS, PAGETYPE,PAGE_NAVIGATION } from "../constants";
import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'
import { AppContext } from '../context';

function DressThreeSupplyChain() {
  const { getData,getParams} = useContext(AppContext);

  const [groupValue, setGroupValue] = useState("1");
  const imageOne = require("../images/dressThreeResale.png");
  const imageTwo = require("../images/dressThreeHome1.png");
  const imageThree = require("../images/dressThreeHome1.png");
  const imageFour = require("../images/dressThreeResale.png");
  const imageFive = require("../images/dressTwoTimeLineImgFive.png");
  const fabricDesign = require("../images/amarezza/Acv.png");

  const navigate = useNavigate();

  const location = useLocation();
  const [getTheme, setTheme] = useState(null);

  useEffect(() => {
    dispatchAnalyticsPageView(location.pathname,PAGETYPE.supply_chain)
    setTheme(filterDataByPage(getData, getParams.styleId, getParams.lang));
  }, [getData]);

  return (
    <>
      {getTheme && (
        <>
          <VStack
            alignItems="flex-start"
            space={0}
            px={"4"}
            paddingTop={"4"}
            marginBottom={"2"}
          >
            {/* <Link href="/la-makanerie/acqua/digitaID/acq0001"> */}
            <Button onPress={() => navigate(-1)} variant={"unstyled"}>
              <CloseIcon color={getTheme?.theme?.color?.primary} size={"4"} />
            </Button>
            {/* </Link> */}
          </VStack>
          <VStack
            alignItems={"center"}
            marginBottom={"6"}
            textAlign={"center"}
            paddingTop={"8"}
          >
            {groupValue === "1" && (
              <>
                <Text
                  fontSize={"32px"}
                  fontFamily={"archivo_regular"}
                  fontWeight={"500"}
                  textAlign={"center"}
                  lineHeight={"26px"}
                  textTransform={"uppercase"}
                >
                  Histoire du Produit
                </Text>
              </>
            )}
            {groupValue === "2" && (
              <>
                <Text
                  fontSize={"32px"}
                  fontFamily={"archivo_regular"}
                  fontWeight={"500"}
                  textAlign={"center"}
                  lineHeight={"26px"}
                  textTransform={"uppercase"}
                >
                  La Matière
                </Text>
              </>
            )}
          </VStack>
          <VStack alignItems={"center"} space={2}>
            {groupValue === "1" && (
              <>
                <Text
                  fontSize={"12px"}
                  color={"#000000"}
                  letterSpacing={"0.46px"}
                  fontFamily={"archivo_regular"}
                  textTransform={"uppercase"}
                  lineHeight={"25px"}
                >
                  8,000 km parcourus à Paris
                </Text>
                <Text
                  fontSize={"12px"}
                  color={"#000000"}
                  letterSpacing={"0.46px"}
                  fontFamily={"archivo_regular"}
                  textTransform={"uppercase"}
                  lineHeight={"25px"}
                >
                  De l’usine à l’entrepôt: 840 KM
                </Text>
              </>
            )}

            {groupValue === "2" && (
              <>
                <Text
                  fontSize={"12px"}
                  color={"#000000"}
                  letterSpacing={"0.46px"}
                  fontFamily={"archivo_regular"}
                  textTransform={"uppercase"}
                  lineHeight={"26px"}
                  fontWeight={"400"}
                >
                  En savoir en plus sur la matière
                </Text>
              </>
            )}
          </VStack>

          <VStack margin={"5"} alignItems="center">
            <Radio.Group
              defaultValue={groupValue}
              accessibilityLabel="pick a page"
              colorScheme={{
                borderColor: getTheme?.theme?.color?.primary,
              }}
              onChange={(value) => {
                setGroupValue(value);
              }}
            >
              <VStack
                direction={{
                  base: "row",
                  md: "row",
                }}
                space={4}
                w="100%"
                // maxW="300px"
              >
                <Radio value="1" colorScheme="red" size="sm" my={1}></Radio>
                <Radio value="2" colorScheme="red" size="sm" my={1}></Radio>
              </VStack>
            </Radio.Group>
          </VStack>

          {groupValue === "1" && (
            <>
              <VStack
                alignItems={"center"}
                space={2}
                textAlign={"center"}
                paddingTop={"8"}
                padding={"5"}
              >
                <Text
                  color={getTheme?.theme?.color?.primary}
                  fontSize="14px"
                  textTransform={"uppercase"}
                  fontWeight="400"
                  textAlign="center"
                  fontFamily={"archivo_regular"}
                  letterSpacing={"md"}
                  lineHeight={"26px"}
                >
                  CERTIFIé par GOTS
                </Text>
                <Text
                  fontSize={"12px"}
                  color={"#000000"}
                  fontFamily={"archivo_regular"}
                  lineHeight={"19px"}
                >
                  Tous notres pieces sont certifées selon l’exigeant label
                  Global Organic Textile Standard (GOTS), car ils sont faits
                  sans des produits toxiques, réduction drastique de
                  l’utilisation en l’eau, des bons conditions pour les
                  travailleurs. Nous travaille dans une logique de slow fashion,
                  et assume son positionnement d'un luxe qui ne fait pas
                  l'économie de l'éthique. 
                </Text>
              </VStack>
              <VerticalTimeline
                lineColor={getTheme?.theme?.color?.secondary}
                animate="false"
                layout="1-column-left"
                className={`timeLineCustom`}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ boxShadow: "none" }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    border: `1px solid ${getTheme?.theme?.color?.secondary}`,
                    boxShadow: "none",
                    padding: "3px",
                    height: "60px",
                    width: "60px",
                  }}
                  icon={
                    <Image
                      source={{
                        uri: getParams.style === "amarezza" ? fabricDesign : imageOne,
                      }}
                      height={"full"}
                      borderRadius={"50%"}
                    />
                  }
                >
                  <VStack space={4}>
                    <Text
                      fontSize={"24px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"26px"}
                      fontWeight={"400"}
                    >
                      Matières premières
                    </Text>
                    <Text
                      fontSize={"12px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"19px"}
                      fontWeight={"300"}
                    >
                      Du fait d’un manque de production en Europe, les fibres
                      vierges proviennent de Chine.
                    </Text>

                    <VStack space={2}>
                      <Text
                        fontSize={"14px"}
                        color={getTheme?.theme?.color?.primary}
                        fontFamily={"archivo_regular"}
                        lineHeight={"26px"}
                        fontWeight={"700"}
                        textTransform={"uppercase"}
                      >
                        chine
                      </Text>
                    </VStack>
                  </VStack>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ boxShadow: "none" }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    border: `1px solid ${getTheme?.theme?.color?.secondary}`,
                    boxShadow: "none",
                    padding: "3px",
                    height: "60px",
                    width: "60px",
                  }}
                  icon={
                    <Image
                      source={{
                        uri: getParams.style === "amarezza" ? fabricDesign : imageTwo,
                      }}
                      height={"full"}
                      borderRadius={"50%"}
                    />
                  }
                >
                  <VStack space={4}>
                    <Text
                      fontSize={"24px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"26px"}
                      fontWeight={"400"}
                    >
                      Tisserand de soie
                    </Text>
                    <Text
                      fontSize={"12px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"19px"}
                      fontWeight={"300"}
                    >
                      Notre tisserand est basé en Italie et garantit un tissage
                      fin et délicat dans le pur savoir-faire de la région de
                      Côme.
                    </Text>
                    <VStack space={2}>
                      <Text
                        fontSize={"14px"}
                        color={getTheme?.theme?.color?.primary}
                        fontFamily={"archivo_regular"}
                        lineHeight={"26px"}
                        fontWeight={"700"}
                        textTransform={"uppercase"}
                      >
                        CÔme, italie
                      </Text>
                    </VStack>
                  </VStack>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ boxShadow: "none" }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    border: `1px solid ${getTheme?.theme?.color?.secondary}`,
                    boxShadow: "none",
                    padding: "3px",
                    height: "60px",
                    width: "60px",
                  }}
                  icon={
                    <Image
                      source={{
                        uri: getParams.style === "amarezza" ? fabricDesign : imageThree,
                      }}
                      height={"full"}
                      borderRadius={"50%"}
                    />
                  }
                >
                  <VStack space={4}>
                    <Text
                      fontSize={"24px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"26px"}
                      fontWeight={"400"}
                    >
                      Teinture et Impressions
                    </Text>
                    <Text
                      fontSize={"12px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"19px"}
                      fontWeight={"300"}
                    >
                      La teinture et l’impression de nos foulards sont réalisées
                      sans produits toxiques ni métaux lourd.
                    </Text>

                    <VStack space={2}>
                      <Text
                        fontSize={"14px"}
                        color={getTheme?.theme?.color?.primary}
                        fontFamily={"archivo_regular"}
                        lineHeight={"26px"}
                        fontWeight={"700"}
                        textTransform={"uppercase"}
                      >
                        cÔmE, italie
                      </Text>
                    </VStack>
                  </VStack>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ boxShadow: "none" }}
                  contentArrowStyle={{ display: "none" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    border: `1px solid ${getTheme?.theme?.color?.secondary}`,
                    boxShadow: "none",
                    padding: "3px",
                    height: "60px",
                    width: "60px",
                  }}
                  icon={
                    <Image
                      source={{
                        uri: getParams.style === "amarezza" ? fabricDesign : imageFour,
                      }}
                      height={"full"}
                      borderRadius={"50%"}
                    />
                  }
                >
                  <VStack space={4}>
                    <Text
                      fontSize={"24px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"26px"}
                      fontWeight={"400"}
                    >
                      La confection
                    </Text>
                    <Text
                      fontSize={"12px"}
                      color={"#000000"}
                      fontFamily={"archivo_regular"}
                      lineHeight={"19px"}
                      fontWeight={"300"}
                    >
                      La confection est réalisée en Italie, dont le savoir-faire
                      artisanal est gage de la plus belle qualité.
                    </Text>

                    <VStack space={2}>
                      <Text
                        fontSize={"14px"}
                        color={getTheme?.theme?.color?.primary}
                        fontFamily={"archivo_regular"}
                        lineHeight={"26px"}
                        fontWeight={"700"}
                        textTransform={"uppercase"}
                      >
                        CÔmE, italie
                      </Text>
                    </VStack>
                  </VStack>
                </VerticalTimelineElement>
              </VerticalTimeline>
              <VStack px={4}>
                <Link
                  href="https://www.la-makanerie.com/notre-terre"
                  width={"full"}
                >
                  <StyledButton
                    text={"EN savoir Plus"}
                    backgroundColor={getTheme?.theme?.color?.primary}
                    textColor="#FFFFFF"
                    borderColor="transparent"
                    width="100%"
                    height="54px"
                    hoverBackgroundColor="#FFFFFF"
                    hoverTextColor={getTheme?.theme?.color?.primary}
                    hoverBorderColor={getTheme?.theme?.color?.primary}
                  />
                </Link>
              </VStack>
            </>
          )}
          {groupValue === "2" && (
            <FabricTemplate primaryColor={getTheme?.theme?.color?.primary} />
          )}
        </>
      )}
    </>
  );
}

export default DressThreeSupplyChain;
