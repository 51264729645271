import {
    Image,
    Text,
    Link,
    VStack,
    Button,
    CloseIcon
  } from "native-base";
  
  function DressOneEndOfLife() {
    var endImage = require('../images/dressOneEndLife.png');
    return (
      <>
          <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'}>
          <Link href="/anewsweden/idANS0030567"> 
          <Button variant={'unstyled'}><CloseIcon color={"primary.600"} size={"4"}/></Button>
          </Link>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"4"} px={4} space={8}>
            <Text color={"primary.600"} fontSize={"4xl"} fontFamily={'heading'}>Upcycle</Text>
            <Text color={"primary.600"} fontSize={'12px'}>To reduce the waste that comes from textiles, we don't let any fabric piece go to waste.</Text>
            <Text color={"primary.600"} marginBottom={"4"} fontSize={'12px'}>If your dress is beyond repair or cannot be resold, send it back to us so we can upcycle the fabric pieces into something new.</Text>
          </VStack>
          <VStack alignItems={"center"} px={4}>  
            <Button width="full" borderColor="primary.600" marginBottom={4}>
            Get your shipping label
          </Button>
          </VStack>
          <VStack alignItems={'center'}>
          <Image
                source={{ uri: endImage }}
                alt="Alternate Text"
                size="xl"
                width="full"
                height="535px"
            />
          </VStack>

          </>
    );
  }
  
  export default DressOneEndOfLife;
  