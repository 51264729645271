import React,{ useEffect, useContext, useState } from "react";
import {
    Box,
    Image,
    Text,
    Link,
    VStack,
    Button,
    CloseIcon,
    Flex
  } from "native-base";
    import { useNavigate } from "react-router-dom";
  import { useLocation } from 'react-router-dom';
  import {filterDataByPage} from '..//utils'
  import StyledButton from '../components/Button'
  import { PAGETYPE } from "../constants";
  import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'
  import { AppContext } from '../context';

  function DressThreeUpcycle() {
    const { getData,getParams} = useContext(AppContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [getTheme, setTheme] = useState(null);
    const [getPageData, setPageData] = useState(null);
    useEffect(()=>{
      dispatchAnalyticsPageView(location.pathname,PAGETYPE.upcycle)
       if( getParams.style === 'acqua'){
          setPageData(filterDataByPage(getData,getParams.styleId,getParams.lang,'upcyle'))
        }
        else {
          setPageData(filterDataByPage(getData,getParams.styleId,getParams.lang,PAGETYPE.upcycle))
        }
        setTheme(filterDataByPage(getData,getParams.styleId,getParams.lang))
     },[getData])
  
    return (
      <>
      {
         getPageData && (
           <>
           <Flex minH={'100vh'} direction="column" justifyContent={'space-between'}>
        <Box>
          <VStack alignItems="flex-start" space={4} px={"4"} paddingTop={'4'}>
              <Button onPress={() => navigate(-1)} variant={'unstyled'}><CloseIcon color={getTheme?.theme?.color?.primary} size={"5"}/></Button>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"4"} px={4} space={'6'}>
            <Text color={""} fontFamily={'archivo_regular'} fontSize={'4xl'} letterSpacing={'0.46px'} >{getPageData?.title?.text}</Text>
          </VStack>
          <VStack alignItems={"center"} px={4} space={'4'}>
            <Text color={''} fontSize={'12px'} fontFamily={'archivo_regular'} >
            {getPageData?.desc?.text}
            </Text>
            <Link href="https://tilli.fr/reserver-votre-couturier-a-domicile/" width={'full'}>
               <StyledButton
              text={getPageData?.actionBtn?.text}
              backgroundColor={getTheme?.theme?.color?.primary}
             textColor="#FFFFFF"
            borderColor="transparent"
            width="100%"
            height="54px"
           hoverBackgroundColor="#FFFFFF"
           hoverTextColor={getTheme?.theme?.color?.primary}
           hoverBorderColor={getTheme?.theme?.color?.primary}
          />
          </Link>
          </VStack>
          <VStack alignItems={'center'} marginTop={4}>
          <Image
                source={{ uri: getPageData?.images?.primary }}
                alt="Alternate Text"
                size="xl"
                width="full"
                height="411px"
            />
          </VStack>
          </Box>
          </Flex>
           </>
         )
        
      }
          </>
    );
  }
  
  export default DressThreeUpcycle;
  