import { useEffect, useState, useContext } from "react";
import { Box, NativeBaseProvider, extendTheme } from "native-base";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DressOneHome from "./pages/dressOneHome";
import DressOneWarranty from "./pages/dressOneWarranty";
import DressOneCare from "./pages/dressOneCaring";
import DressOneEndOfLife from "./pages/dressOneEndOfLife";
import DressOneRepair from "./pages/dressOneRepair";
import DressOneResale from "./pages/dressOneResale";
import DressOneSupplyChain from "./pages/dressOneSupplyChain";
import DressTwoHome from "./pages/dressTwoHome";
import DressTwoWarranty from "./pages/dressTwowarranty";
import DressTwoResale from "./pages/dressTwoResale";
import DressTwoSupplyChain from "./pages/dressTwoSupplyChain";
import DressTwoRepair from "./pages/dressTwoRepair";
import DressTwoCare from "./pages/dressTwoCaring";
import DressTwoEndOfLife from "./pages/dressTwoEndOfLife";
import Home from "./pages/home";
import Resale from "./pages/Resale";
import Repair from "./pages/Repair";
import Upcycle from "./pages/Upcycle";
import Return from "./pages/Return";
import Caring from "./pages/Caring";
import SupplyChain from "./pages/SupplyChain";
import Material from "./pages/Material";
import Error from "./pages/Error";
import "./font.css";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import madi_ico from "../src/images/fav-ico/madi.ico";
import la_makanerie_ico from "../src/images/fav-ico/logoMakanerie.ico";
import anewsweden_ico from "../src/images/fav-ico/anewsweden.ico";
import Loader from "./components/Loader";
import { AppContext } from "./context";
import { getDocument } from "./utils/firebase/getDoc";
import { COLLECTION } from "./constants";
ReactGA.initialize("G-4NS2DEHP43");
const la_makanarie = "la-makanerie";
const anewsweden = "anewsweden";
function App() {
  const [getRoute, setRoute] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [isVerified, setVerify] = useState(false);
  const { setParams, setData } = useContext(AppContext);

  useEffect(() => {
    const currentURL = window.location.href;
    // Use the URL constructor to parse the URL
    const urlObj = new URL(currentURL);

    // Extract the "la-makanerie" value from the pathname
    const pathnameParts = urlObj.pathname.split("/");
    setRoute(pathnameParts[1]);

    const params = new URLSearchParams(window.location.search);

    // Extract values from the URL
    const style = params.get("style");
    const orgId = params.get("orgId");
    const styleId = params.get("styleId");
    const lang = params.get("lang");
    const digitalId = params.get("digitalId");

    const urlParams = {
      style,
      orgId,
      styleId,
      lang,
      digitalId,
    };
    setParams(urlParams);

    const fetchData = async () => {
      const isVerified = await verifyDigitalId(styleId, digitalId);
      if (isVerified) {
        setVerify(true);
        getDocument(COLLECTION.templetes, orgId)
          .then((result) => {
            setData(result);
            setShowLoader(false);
          })
          .catch((e) => {
            setShowLoader(false);
          });
      } else {
        setVerify(false);
        setShowLoader(false);
      }
    };
    fetchData();
  }, []);
  const verifyDigitalId = async (styleId, digitalId) => {
    let value = digitalId === "demo" ? true : false;
    // if digital id is not demo, then validation process should execute
    if (digitalId !== "demo") {
      await getDocument(COLLECTION.styles, styleId)
        .then((result) => {
          const index = result.products.indexOf(digitalId);
          if (index !== -1) {
            value = true;
          }
        })
        .catch((e) => {
          value = false;
        });
    }

    return value;
  };

  const theme = extendTheme({
    colors: {
      primary: {
        50: "#E9EDFB",
        100: "#C2CDF5",
        200: "#9AADEE",
        300: "#738DE8",
        400: "#4C6CE1",
        500: "#244CDB",
        600: "#882517",
        700: "#162E83",
        800: "#0F1E57",
        900: "#070F2C",
      },
    },
    fonts: {
      heading: "Ivar Fine",
      body: "Univers LT Std Light",
      bodyBold: "Univers LT Std",
      heading_three: "AttilaSansBasic Regular",
      subheading_three: "AttilaSansBasic Light",
      body_three: "AttilaSansBasic Thin",
      bodybold_three: "AttilaSansBasic SemiBold",
      bodymedium_three: "AttilaSansBasic Medium",
      archivo_regular: "Archivo Regular",
      archivo_black: "ArchivoExpanded Black",
    },

    components: {
      Button: {
        baseStyle: {
          borderRadius: "none",
          textTransform: "uppercase",
          _text: {
            paddingTop: "3px",
            letterSpacing: "2px",
          },
        },
        variants: {
          outline: {
            borderWidth: "1px",
          },
        },
        sizes: {
          md: {
            py: "3",
          },
        },
      },
    },
  });
  return (
    <NativeBaseProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getRoute}</title>
        <link
          id="favicon"
          rel="icon"
          href={
            getRoute === anewsweden
              ? anewsweden_ico
              : getRoute === la_makanarie
              ? la_makanerie_ico
              : madi_ico
          }
          type="image/x-icon"
        />
      </Helmet>
      {showLoader ? (
        <Loader />
      ) : (
        <Box bg="white" minHeight="100vh" justifyContent="center" px={0} my={0}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/anewsweden/idANS0030567"
                element={<DressOneHome />}
              />
              <Route
                path="/anewsweden/idANS0030567/SupplyChain"
                element={<DressOneSupplyChain />}
              />
              <Route
                path="/anewsweden/idANS0030567/Warranty"
                element={<DressOneWarranty />}
              />
              <Route
                path="/anewsweden/idANS0030567/Care"
                element={<DressOneCare />}
              />
              <Route
                path="/anewsweden/idANS0030567/EndOfLife"
                element={<DressOneEndOfLife />}
              />
              <Route
                path="/anewsweden/idANS0030567/Repair"
                element={<DressOneRepair />}
              />
              <Route
                path="/anewsweden/idANS0030567/Resale"
                element={<DressOneResale />}
              />

              <Route
                path="/anewsweden/idANS0010215"
                element={<DressTwoHome />}
              />
              <Route
                path="/anewsweden/idANS0010215/Warranty"
                element={<DressTwoWarranty />}
              />
              <Route
                path="/anewsweden/idANS0010215/Resale"
                element={<DressTwoResale />}
              />
              <Route
                path="/anewsweden/idANS0010215/SupplyChain"
                element={<DressTwoSupplyChain />}
              />
              <Route
                path="/anewsweden/idANS0010215/Repair"
                element={<DressTwoRepair />}
              />
              <Route
                path="/anewsweden/idANS0010215/Care"
                element={<DressTwoCare />}
              />
              <Route
                path="/anewsweden/idANS0010215/EndOfLife"
                element={<DressTwoEndOfLife />}
              />

              <Route
                path={`/invalid`}
                element={
                  <Error
                    errorTitle={"404 : Invalid Digital ID"}
                    errorMessage={"Please use a valid Digital ID"}
                  />
                }
              />
              <Route
                path={`/${getRoute}`}
                element={isVerified ? <Home /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/resale`}
                element={isVerified ? <Resale /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/repair`}
                element={isVerified ? <Repair /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/upcycle`}
                element={isVerified ? <Upcycle /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/return`}
                element={isVerified ? <Return /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/care`}
                element={isVerified ? <Caring /> : <Navigate to="/invalid" />}
              />
              <Route
                path={`/${getRoute}/supplyChain`}
                element={
                  isVerified ? <SupplyChain /> : <Navigate to="/invalid" />
                }
              />
              <Route
                path={`/${getRoute}/material`}
                element={isVerified ? <Material /> : <Navigate to="/invalid" />}
              />
            </Routes>
          </BrowserRouter>
        </Box>
      )}
    </NativeBaseProvider>
  );
}

export default App;
