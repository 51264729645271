import React, { createContext, useState, useContext } from "react";
const AppContext = createContext();
const AppContextProvider = ({ children }) => {
  const [getData, setData] = useState(null);
  const [getParams, setParams] = useState(null);

  return (
    <AppContext.Provider value={{ getData, setData, getParams, setParams}}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to access the context values
const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};


export { AppContext, AppContextProvider,useAppContext };
