import { doc, getDoc } from "firebase/firestore";
import { fs } from "../../firebase/firebase.config";
export const getDocument = async (collectionName, id) => {
  try {
    const docRef = doc(fs, collectionName, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (e) {
    console.error("No such document! ", e);
  }
};
