import {
    Image,
    Text,
    Link,
    VStack,
    Button,
    CloseIcon
  } from "native-base";
  
  function DressOneCare() {
    var careImage = require('../images/careImage.png');
    return (
      <>
          <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'} marginBottom={'4'}>
            <Link href="/anewsweden/idANS0030567">
              <Button variant={'unstyled'}><CloseIcon color={"primary.600"} size={"4"}/></Button>
            </Link>
          </VStack>
          <VStack alignItems={"center"} marginBottom={'8'}>
            <Text color={"primary.600"} fontSize={"4xl"} fontFamily={'heading'} letterSpacing={'0.46px'}>Caring for your garment</Text>
          </VStack>
          <VStack alignItems={"center"} space={0} px={"4"} marginBottom={8}>
            <Text color={"primary.600"} alignContent={"center"} fontSize={'12px'}>
            Wool is one of easiest materials to care for if cared for in the right way. It is also one of the longest lasting materials, giving you a better cost per wear.
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"} px={"4"}>

            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'} textTransform={"uppercase"}>Low maintenance</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>Wool is naturally antibacterial and dirt and odor resistant. Instead of washing your garment every few wears, hang it up to air out instead.</Text>

            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'} textTransform={"uppercase"}>Naturally stain-repellent</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>Stains can be easily removed from this garment by spot cleaning with soap and water.</Text>

            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'}  textTransform={"uppercase"}>Wear in to prevent pilling</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>Pilling occurs during the wearing-in phase of 100% wool garments. After removing with a razor or comb, that area will no longer pill.</Text>

            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'}  textTransform={"uppercase"}>Store with care</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>When not in use, we recommend folding your knitted garments flat and hanging up your woven garments.</Text>
            
            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'}  textTransform={"uppercase"}>Washing cycle</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>If you must wash your wool garment, it can be washed on the delicate or wool cycle at 30° with wool shampoo.</Text>

            <Text color={"primary.600"} fontSize={'15px'} fontFamily={'bodyBold'} letterSpacing={'2px'}  textTransform={"uppercase"}>Dry flat</Text>
            <Text color={"primary.600"} marginBottom={"8"} fontSize={'12px'}>Never put your wool items in the dryer, it will shrink and ruin the fabric. Instead air dry by lying it flat on a drying rack.</Text>
          </VStack>
          <VStack alignItems={'center'}>
            <Image
                source={{ uri: careImage }}
                alt="Alternate Text"
                size="xl"
                width="full"
                height="570px"
            />
          </VStack>
  

  

          </>
    );
  }
  
  export default DressOneCare;
  