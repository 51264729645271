import React,{useState,useEffect,useContext} from "react";
import {
    Image,
    Text,
    Link,
    VStack,
    Button,
    Divider,
    CloseIcon,
    Flex,
    Radio
  } from "native-base";
  import { useNavigate } from "react-router-dom";
  import StyledButton from '../components/Button'
  import { useLocation } from 'react-router-dom';
  import {filterDataByPage} from '..//utils'
  import {PAGETYPE} from '../constants'
  import {dispatchAnalyticsPageView} from '../utils'
  import { AppContext } from '../context';

  function DressThreeResale() {
    var resaleImage = require('../images/dressThreeResale.png');
    const navigate = useNavigate();
    const location = useLocation();
    const [getTheme, setTheme] = useState(null);
    const MotifAmarezza = require("../images/amarezza/MotifAmarezza.png");
    const { getData,getParams} = useContext(AppContext);

    useEffect(()=>{
      dispatchAnalyticsPageView(location.pathname,PAGETYPE.resale)
      setTheme(filterDataByPage(getData,getParams.styleId,getParams.lang))
    },[getData])

    return (
      <>
      {
        getTheme && (
<>
<VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'}>
            <Button  onPress={() => navigate(-1)} variant={'unstyled'}><CloseIcon color={getTheme?.theme?.color?.primary} size={"4"}/></Button>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"8"}>
            <Text color={""} fontSize={"4xl"} fontFamily={'archivo_regular'}>Revendre</Text>
          </VStack>
          <VStack alignItems={"center"} space={0} px={"4"} marginBottom={8} textAlign={'center'}>
            <Text color={""} fontSize={"26px"} alignContent={"center"} fontFamily={'archivo_regular'} >
            Vous voulez revendre votre foulard ?
            </Text>
            <br></br>
            <Text color={""} fontFamily={'archivo_regular'} fontSize={"12px"}>Vendre votre produit avec Vestiaire Collective.</Text>
            <br></br>
            <Image
                source={{ uri: getParams.style ==='amarezza'? MotifAmarezza: resaleImage }}
                alt="Alternate Text"
                size="xl"
                width="262px"
                height="365px"
            />
          </VStack>
          <Flex justifyContent={'space-between'} direction="row" px={'4'} marginBottom={'2'} >
                <Text color={""} fontFamily={'archivo_regular'} fontSize={"12px"}>Prix d’achat :</Text>
                <Text color={""} fontFamily={'archivo_regular'} fontSize={"12px"}>290 €</Text>
          </Flex>
          <VStack alignItems={'center'} mx={'2'} marginTop={'8'} marginBottom={'4'}>
           <Divider/>
          </VStack>
          <VStack alignItems={'flex-start'} px={4} marginBottom={'4'}>
            <Text color="" textTransform={''} fontFamily={'archivo_regular'}>Retour à la page revente</Text>
          </VStack>
          <Flex direction="row" px={'4'} justifyContent={'space-between'} alignItems={'center'}>
          <Radio.Group display={'flex'} direction={'row'} alignItems={'center'}
           colorScheme={{
            borderColor:getTheme?.theme?.color?.primary
          }}>
          <Radio mr={'2'}></Radio><Text color={getTheme?.theme?.color?.primary} fontWeight={'extrabold'} fontFamily={'archivo_regular'}>Vestiaire <br/>Collective</Text>
            </Radio.Group>
          </Flex>
          <VStack alignItems={'center'} px={4} marginBottom={'4'} py={'2'}>
          <Link href="https://de.vestiairecollective.com/journal/start-selling/" width={'full'}>
           <StyledButton
              text={'Continuer'}
              backgroundColor={getTheme?.theme?.color?.primary}
             textColor="#FFFFFF"
            borderColor="transparent"
            width="100%"
            height="54px"
           hoverBackgroundColor="#FFFFFF"
           hoverTextColor={getTheme?.theme?.color?.primary}
           hoverBorderColor={getTheme?.theme?.color?.primary}
          />
          </Link>
          </VStack>
</>
        )
      }
         
          
          </>
    );
  }
  
  export default DressThreeResale;
  