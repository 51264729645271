import {PAGETYPE} from '../constants'
import ReactGA from "react-ga4";

export const filterDataByPage = (result,styleId,lang,pageName)=>{
  let pageData = null;
  switch(pageName){
    case PAGETYPE.home_page:
      pageData = result?.styles[styleId][lang].pages?.home_page
      break;
    case PAGETYPE.interview:
      pageData = result?.styles[styleId][lang].pages?.interview
      break;  
    case PAGETYPE.warranty:
      pageData= result?.styles[styleId][lang].pages?.warranty
      break;
    case PAGETYPE.upcycle:
      pageData = result?.styles[styleId][lang].pages?.upcycle
      break; 
    case 'upcyle':
        pageData = result?.styles[styleId][lang].pages?.upcyle
      break;       
    default:
      pageData = result?.styles[styleId]      
  }
  return pageData;
}

export const dispatchAnalyticsEvent = 
(category,label,orgId,digitalId,
  styleId,style,lang
  ) => {
  ReactGA.event({
    category: category,
    action:label,
    orgId:orgId,
    digitalId:digitalId,
    styleId:styleId,
    style:style,
    lang:lang
  });
}

export const dispatchAnalyticsPageView = (path,title) =>{
  ReactGA.send({ hitType: "pageview", page: path, title: title });
}