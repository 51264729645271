import React, { useState, useEffect,useContext } from "react";
import {
  Box,
  Image,
  Text,
  Link,
  VStack,
  Button,
  Select,
  CloseIcon,
  Flex,
} from "native-base";
import { useNavigate } from "react-router-dom";
import StyledButton from "../components/Button";
import { useLocation } from "react-router-dom";
import { getDocument } from "..//utils/firebase/getDoc";
import { filterDataByPage } from "..//utils";
import { COLLECTION, DESKTOPSIZE, PAGETYPE } from "../constants";
import StyledDropdown from "../components/Dropdown";
import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'
import { AppContext } from '../context';

import StyledImage from "../components/Image";
function DressThreeRepair() {
  var manufacImage = require("../images/dressThreeRepair.png");
  const [service, setService] = React.useState("");
  const location = useLocation();
  const [getTheme, setTheme] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get("orgId");
  const digitalId = queryParams.get("digitalId");
  const styleId = queryParams.get("styleId");
  const lang = queryParams.get("lang");
  const style = queryParams.get("style");
  const Amarezza1 = require("../images/amarezza/Amarezza1.png");
  const { getData,getParams} = useContext(AppContext);

  useEffect(() => {
    dispatchAnalyticsPageView(location.pathname,PAGETYPE.repair)
    setTheme(filterDataByPage( getData, getParams.styleId, getParams.lang));
  }, [getData]);
  const navigate = useNavigate();
  return (
    <>
      {getTheme && (
        <>
          <Flex
            minH={"100vh"}
            direction="column"
            justifyContent={"space-between"}
          >
            <Box>
              <VStack
                alignItems="flex-start"
                space={4}
                px={"4"}
                paddingTop={"4"}
              >
                {/* <Link href="/la-makanerie/acqua/digitaID/acq0001"> */}
                <Button onPress={() => navigate(-1)} variant={"unstyled"}>
                  <CloseIcon
                    color={getTheme?.theme?.color?.primary}
                    size={"5"}
                  />
                </Button>
                {/* </Link> */}
              </VStack>
              <VStack
                alignItems={"center"}
                marginBottom={"4"}
                px={4}
                space={"6"}
              >
                <Text
                  color={""}
                  fontFamily={"archivo_regular"}
                  fontSize={"4xl"}
                  letterSpacing={"0.46px"}
                >
                  Retoucher
                </Text>
                <Text
                  color={""}
                  fontSize={"2xl"}
                  fontFamily={"archivo_regular"}
                  textAlign={"center"}
                >
                  Vous avez besoin d'une retouche ?
                </Text>
              </VStack>
              <VStack
                marginBottom={"4"}
                alignItems={window.innerWidth > DESKTOPSIZE ? "center" : ""}
                px={4}
                space={"6"}
              >
                <Text color={""} fontSize={"12px"} fontFamily={"archivo_regular"}>
                  Choisissez un option:
                </Text>
              </VStack>
              <VStack
                alignItems={"center"}
                marginBottom={"4"}
                px={4}
                space={"6"}
              >
                <Select
                  selectedValue={service}
                  minWidth="200"
                  width="80"
                  accessibilityLabel="Sélectionnez une option"
                  placeholder="Sélectionnez une option"
                  _selectedItem={{
                    bg: getTheme?.theme?.color?.primary,
                  }}
                  mt={1}
                  onValueChange={(itemValue) => setService(itemValue)}
                >
                  <Select.Item label="Modifier" value="modifier" />
                  <Select.Item label="Réparer" value="réparer" />
                </Select>
              </VStack>
              <VStack alignItems={"center"} px={4} space={"4"}>
                <Text color={""} fontSize={"12px"} fontFamily={"archivo_regular"}>
                  Vous êtes en France ? Réparez ou transformez facilement votre
                  foulard grâce à notre partenaire Tilli.
                </Text>
                <Text color={""} fontSize={"12px"} fontFamily={"archivo_regular"}>
                  Cliquez ci-dessus pour vous connecter au site de Tilli et
                  prendre RDV avec un professionnel spécialisé. 
                </Text>
                <Link
                  href="https://tilli.fr/reserver-votre-couturier-a-domicile/"
                  width={window.innerWidth > DESKTOPSIZE ? "40%" : "100%"}
                >
                  {/* <Button variant={'solid'} width="full" colorScheme={'primary'} my={4}>
            <Text color={'white'} fontSize={'12px'} fontFamily={'archivo_regular'} >
            Prendre rendez-vous
            </Text>
          </Button> */}

                  <StyledButton
                    text={"Prendre rendez-vous"}
                    backgroundColor={getTheme?.theme?.color?.primary}
                    textColor="#FFFFFF"
                    borderColor="transparent"
                    width="100%"
                    height="54px"
                    hoverBackgroundColor="#FFFFFF"
                    hoverTextColor={getTheme?.theme?.color?.primary}
                    hoverBorderColor={getTheme?.theme?.color?.primary}
                  />
                </Link>
              </VStack>
            </Box>
            <VStack alignItems={"center"} marginTop={4}>
              <StyledImage
                width="40%"
                src={style === "amarezza" ? Amarezza1 : manufacImage}
                alt={"Image of the product design 2"}
              />
            </VStack>
          </Flex>
        </>
      )}
    </>
  );
}

export default DressThreeRepair;
