import { useEffect, useContext, useState } from "react";
import { Image, Text, Link, VStack, Button } from "native-base";
import { useLocation } from "react-router-dom";
import { getDocument } from "../utils/firebase/getDoc";
import { filterDataByPage } from "../utils";
import { ACTIONS, COLLECTION, EVENTS, PAGETYPE,PAGE_NAVIGATION } from "../constants";
import StyledButton from "../components/Button";
import StyledOutlinedButton from "../components/OutlinedButton";
import StyledImage from "../components/Image";
import {dispatchAnalyticsEvent,dispatchAnalyticsPageView} from '../utils'
import {useNavigate} from "react-router-dom";
import { AppContext } from '../context';

function DressThreeHome(props) {
  const location = useLocation();
  const [getPageData, setPageData] = useState(null);
  const [getTheme, setTheme] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get("orgId");
  const digitalId = queryParams.get("digitalId");
  const styleId = queryParams.get("styleId");
  const lang = queryParams.get("lang");
  const style = queryParams.get("style");
  const MotifAmarezza = require("../images/amarezza/MotifAmarezza.png");
  const Amarezza2 = require("../images/amarezza/Amarezza2.png");
  const navigate = useNavigate();
  const { getData,getParams} = useContext(AppContext);

  useEffect(() => {
    setPageData(filterDataByPage(getData, getParams.styleId, getParams.lang, PAGETYPE.home_page));
    setTheme(filterDataByPage(getData, getParams.styleId, getParams.lang));
    dispatchAnalyticsPageView(location.pathname,PAGETYPE.home_page)
  }, [getData]);

  const onSelect = (type) => {
    switch(type){
      case PAGETYPE.return:
        navigate(`${location.pathname}/${PAGETYPE.return}${location.search}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.RETURN,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      case PAGETYPE.care:
        navigate(`${location.pathname}/${PAGETYPE.care}${location.search}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.CARE,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      case PAGETYPE.upcycle:
        navigate(`${location.pathname}/${PAGETYPE.upcycle}${location.search}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.UPCYCLE,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      case PAGETYPE.repair:
        navigate(`${location.pathname}/${PAGETYPE.repair}${location.search}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.REPAIR,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      case PAGETYPE.resale:
        navigate(`${location.pathname}/${PAGETYPE.resale}${location.search}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.RESALE,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      case ACTIONS.OPEN_MAIL:
        window.location.href = 'mailto:contact@la-makanerie.com'
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.HELP_AND_CONTACT,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)
      break;
      default:
        navigate(`${location.pathname}`)
        dispatchAnalyticsEvent(ACTIONS.PAGE_NAVIGATION,EVENTS.HOME_PAGE,getParams.orgId,getParams.digitalId,
          getParams.styleId,getParams.style,getParams.lang)

    }
  }
  return (
    <>
      {getPageData && (
        <>
          <VStack alignItems="center" space={2} py={"4"} paddingTop={"30px"}>
            <Image
              source={{ uri: getPageData.images?.logo }}
              alt="Logo"
              width="118px"
              height="60px"
            />
          </VStack>
          <VStack marginBottom={"4"} px={4} space={"6"}>
            <Text
              color={""}
              fontSize={"12px"}
              fontFamily={"archivo_regular"}
              textTransform={"uppercase"}
              textAlign={"center"}
            >
              {getPageData.brandLabel?.text}
            </Text>
          </VStack>
          <VStack alignItems="center" space={2} px={"4"}>
            <Image
              source={{
                uri:
                  style === "amarezza"
                    ? MotifAmarezza
                    : getPageData.images?.primary,
              }}
              alt="Image of the product design"
              height="343px"
              width="353px"
            />
          </VStack>
          <br></br>
          <VStack alignItems={"center"} space={"3"}>
            <Text color="" fontSize="xs" fontFamily={"archivo_regular"}>
              {getPageData.auth_label?.text}
            </Text>
            <Text
              color=""
              fontSize="26px"
              textAlign="center"
              fontFamily={"archivo_regular"}
              letterSpacing={"md"}
            >
              {getPageData.mainStyleDesc?.text}
            </Text>
            <Text
              color=""
              fontSize="sm"
              marginBottom={6}
              fontFamily={"archivo_regular"}
            >
              {` ${getPageData.digitalID?.text}: ${digitalId}`}
            </Text>
          </VStack>
          <VStack alignItems="center">
            <StyledImage
              width='40%'
              src={
                style === "amarezza" ? Amarezza2 : getPageData.images?.secondary
              }
              alt={"Image of the product design 2"}
            />
          </VStack>
          <VStack alignItems="flex-start" px={4} py={4} space={4}>
            <Text
              color=""
              fontSize="22px"
              alignContent="flex-start"
              fontFamily={"archivo_regular"}
              letterSpacing={"lg"}
            >
              {getPageData.secondTitle?.text}
            </Text>
            <Text color="" fontFamily={"archivo_regular"} fontSize="12px">
              {getPageData.secondaryStyleDesc?.text}
            </Text>
            <Link
              href={`/la-makanerie/supplyChain${location.search}`}
              width={"full"}
            >
              <Text
                color=""
                py={4}
                textTransform={"uppercase"}
                fontFamily={"archivo_regular"}
                fontSize="12px"
              >
                {getPageData.productHistory?.text} →
              </Text>
            </Link>
          </VStack>

          <VStack
            alignItems="center"
            space={2}
            marginBottom={7}
            mx={4}
            fontFamily={"archivo_regular"}
          >
            {/* <Link
              href={`/la-makanerie/return${location.search}`}
              width={"full"}
            > */}
              <StyledOutlinedButton
                onClick={()=>onSelect(PAGETYPE.return)}
                backgroundColor="transparent"
                textColor={getTheme?.theme?.color?.primary}
                borderColor={getTheme?.theme?.color?.primary}
                width="100%"
                height="54px"
                text={getPageData.returns_exchanges?.text}
              />
            {/* </Link> */}
            {/* <Link href={`/la-makanerie/care${location.search}`} width={"full"}> */}
              <StyledOutlinedButton
                backgroundColor="transparent"
                textColor={getTheme?.theme?.color?.primary}
                borderColor={getTheme?.theme?.color?.primary}
                width="100%"
                height="54px"
                text={getPageData.interview?.text}
                onClick={()=>onSelect(PAGETYPE.care)}
              />
            {/* </Link> */}
          </VStack>
          <VStack alignItems="center" space={2} mx={4} marginBottom={5}>
            {/* <Link
              href={`/la-makanerie/repair${location.search}`}
              width={"full"}
            > */}
              <StyledOutlinedButton
                onClick={()=>onSelect(PAGETYPE.repair)}
                backgroundColor="transparent"
                textColor={getTheme?.theme?.color?.primary}
                borderColor={getTheme?.theme?.color?.primary}
                width="100%"
                height="54px"
                text={getPageData.retoucher?.text}
              />
            {/* </Link> */}
            {/* <Link
              href={`/la-makanerie/resale${location.search}`}
              width={"full"}
            > */}
              <StyledOutlinedButton
               onClick={()=>onSelect(PAGETYPE.resale)}
                backgroundColor="transparent"
                textColor={getTheme?.theme?.color?.primary}
                borderColor={getTheme?.theme?.color?.primary}
                width="100%"
                height="54px"
                text={getPageData.resell?.text}
              />
            {/* </Link> */}
            {/* <Link
              href={`/la-makanerie/upcycle${location.search}`}
              width={"full"}
            > */}
              <StyledOutlinedButton
                onClick={()=>onSelect(PAGETYPE.upcycle)}
                backgroundColor="transparent"
                textColor={getTheme?.theme?.color?.primary}
                borderColor={getTheme?.theme?.color?.primary}
                width="100%"
                height="54px"
                text={getPageData.upcycle?.text}
              />
            {/* </Link> */}
            {/* <Link href="mailto:contact@la-makanerie.com" width={"full"}> */}
              <StyledButton
                onClick={()=> onSelect(ACTIONS.OPEN_MAIL)}
                text={getPageData.contact?.text}
                backgroundColor={getTheme?.theme?.color?.primary}
                textColor="#FFFFFF"
                borderColor="transparent"
                width="100%"
                height="54px"
                hoverBackgroundColor="#FFFFFF"
                hoverTextColor={getTheme?.theme?.color?.primary}
                hoverBorderColor={getTheme?.theme?.color?.primary}
              />
            {/* </Link> */}
          </VStack>
        </>
      )}
    </>
  );
}

export default DressThreeHome;
