import {
    Image,
    Text,
    Link,
    VStack,
    Button,
    CloseIcon
  } from "native-base";
  import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
  import 'react-vertical-timeline-component/style.min.css';
  import './dressThreeSupplyChain.css';
  import worm from '../images/Group 14.png'
  import pallete from '../images/pallete.png'
  import leaf from '../images/Icons.png'
  
  
  
  function DressThreeMaterial() {
    var imageOne = require("../images/dressThreeResale.png");
    var imageTwo = require("../images/dressThreeHome1.png");
    var imageThree = require("../images/dressThreeHome1.png");
    var imageFour = require("../images/dressThreeResale.png");
    var imageFive = require("../images/dressTwoTimeLineImgFive.png");
    return (
      <>
            <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'} marginBottom={'2'}>
              <Link href="/la-makanerie/acqua/digitaID/acq0001">
                <Button variant={'unstyled'}><CloseIcon color={"primary.600"} size={"4"}/></Button>
              </Link>
            </VStack>
            <VStack alignItems={"center"} marginBottom={"6"} textAlign={'center'} paddingTop={'8'}>
              <Text  fontSize={"4xl"} fontFamily={'archivo_regular'} lineHeight={'30px'}>La Matière</Text>
            </VStack>
            <VStack alignItems={'center'} space={2}>
              <Text  fontSize={'10px'} letterSpacing={'0.46px'} textTransform={'uppercase'} fontFamily={'archivo_regular'}>En savoir en plus sur la matière</Text>
            </VStack>
           
       

    <VStack space={4} padding={'20px'}>
      <br></br>
      <Text fontFamily={'archivo_regular'}  fontSize={'2xl'} letterSpacing={'0.46px'}>Soie</Text>
      <Text  fontSize={'11px'} fontFamily={'archivo_regular'}>Bien que l’industrie européenne occupait autrefois une part importante du marché, elle s’est aujourd’hui réduite et a permis à la soie asiatique traditionnelle de devenir plus répandue. Notre soie provient de producteurs de soie chinois, qui respectent les critères environnementaux et sociaux les plus stricts.</Text>
       </VStack>
 
    <VStack space={4} padding={'20px'}>
      
      <Text color={'primary.600'}  fontFamily={'archivo_regular'} textTransform={'uppercase'}  fontSize={'15px'} letterSpacing={'0.46px'}>LA Source</Text>
      <VStack alignItems="center" space={2} px={"4"}>
            <Image
              source={{ uri: worm }}
              alt="Alternate Text"
              height="30px"
              width="30px"
            />
          </VStack>
      <Text  fontSize={'11px'} fontFamily={'archivo_regular'}> La soie est une fibre naturelle issue du ver à soie. Lors de leur transformation de larve en papillon, les vers à soie s'enroulent dans un cocon de soie. Une fois le cocon entièrement formé, il est bouilli et les fibres de soie sont récoltées. Le matériau obtenu et les produits crées à partir de celui-ci sont solides et durables.</Text>
    
  
    </VStack>

    <VStack space={4} padding={'20px'}>
      
      <Text color={'primary.600'}  fontFamily={'archivo_regular'} textTransform={'uppercase'}  fontSize={'15px'} letterSpacing={'0.46px'}>LA Durabilité</Text>
      <VStack alignItems="center" space={2} px={"4"}>
            <Image
              source={{ uri: pallete }}
              alt="Alternate Text"
              height="40px"
              width="220px"
            />
          </VStack>
      <Text  fontSize={'11px'} fontFamily={'archivo_regular'}>Malgré la délicatesse de la soie, elle reste à ce jour l’un des textiles les plus durables.</Text>
     
    </VStack>

    <VStack space={4} padding={'20px'}>
      
      <Text color={'primary.600'}  fontFamily={'archivo_regular'} textTransform={'uppercase'}  fontSize={'15px'} letterSpacing={'0.46px'}>Biodegradabilité</Text>
      <VStack alignItems="center" space={2} px={"4"}>
            <Image
              source={{ uri: leaf }}
              alt="Alternate Text"
              height="30px"
              width="30px"
            />
          </VStack>
      <Text  fontSize={'11px'} fontFamily={'archivo_regular'}>Dans de bonnes conditions, la soie conventionnelle met entre un à deux ans pour se dégrader complètement dans l’environnement. Le fait qu’un produit se dégrade rapidement n’est pas nécessairement une bonne nouvelle pour l’écosystème.
<br></br><br></br>
 La soie utilisée pour fabriquer nos produits a été produite dans des conditions strictes qui interdisent l'utilisation de produits chimiques toxiques et de métaux lourds tout au long de sa chaîne de production : de l'élevage des vers à la récolte des fibres, en passant par le blanchiment et la teinture. Cela lui permet de se biodégrader et de ne causer pratiquement aucun dommage à l’environnement.</Text>
   
    </VStack>
  

      </>
    );
  }
  
  export default DressThreeMaterial;
  