import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components for the dropdown
const CustomDropdown = styled.div`
  width: ${(props) => props.width || '200px'};
  height: ${(props) => props.height || '40px'};
  border: 1px solid #D8DAE5;
  position: relative;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const DropdownOptions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid #D8DAE5;
  border-top: none;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: #D8DAE5;
`;

const DropdownOption = styled.li`
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #eee; // Highlight on hover
  }

  &.selected {
    background-color: #007bff; // Highlight when selected
    color: #fff;
  }
`;

// Dropdown component
function StyledDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { width, height, placeholder, options } = props;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <CustomDropdown width={width} height={height}>
      <DropdownButton onClick={toggleDropdown}>
        {selectedOption || placeholder || 'Select an option'}
      </DropdownButton>
      <DropdownOptions isOpen={isOpen}>
        {options.map((option, index) => (
          <DropdownOption
            key={index}
            onClick={() => selectOption(option)}
            className={selectedOption === option ? 'selected' : ''}
          >
            {option}
          </DropdownOption>
        ))}
      </DropdownOptions>
    </CustomDropdown>
  );
}

export default StyledDropdown;
