export const COLLECTION = {
  templetes: "templetes",
  styles:"styles"
};

export const ACTIONS = {
  PAGE_NAVIGATION : "PAGE_NAVIGATION",
  OPEN_MAIL : "OPEN_MAIL"
}


export const PAGETYPE = {
  home_page: "home_page",
  interview: "interview",
  upcycle: "upcycle",
  warranty: "warranty",
  resale:"resale",
  repair:"repair",
  return:"return",
  care:"care",
  supply_chain:"supply_chain",
  fabric_information:"fabric_information"
};

export const STYLES = {
  acqua: "acqua",
  amarezza: "amarezza",
};

export const EVENTS = {
  HOME_PAGE:"home_page",
  RETURN:"return",
  CARE:"care",
  UPCYCLE:"upcycle",
  REPAIR:"repair",
  RESALE:"resale",
  SUPPLY_CHAIN:"supply_chain",
  FABRIC_INFORMATION:"fabric_information",
  HELP_AND_CONTACT:"help_and_contact",
  LEARN_MORE:"learn_more",
  CREATE_RETURN:"create_return",
  UPCYCLE_SELECTED:"upcycle_selected",
  MAKE_APPOINTMENT:"make_appointment",
  CONTINUE:"continue"
}

export const DESKTOPSIZE = 768;


