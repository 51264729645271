import {
  Image,
  Text,
  Link,
  VStack,
  Button,
  CloseIcon
} from "native-base";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './dressOneSupplyChain.css';



function DressTwoSupplyChain() {
  var imageOne = require("../images/dressOneTimeLineImgOne.png");
  var imageTwo = require("../images/dressOneTimeLineImgTwo.png");
  var imageThree = require("../images/dressOneTimeLineImgThree.png");
  var imageFour = require("../images/dressOneTimeLineImgFour.png");
  var imageFive = require("../images/dressTwoTimeLineImgFive.png");
  return (
    <>
          <VStack alignItems="flex-start" space={0} px={"4"} paddingTop={'4'} marginBottom={'2'}>
            <Link href="/anewsweden/idANS0010215">
              <Button variant={'unstyled'}><CloseIcon color={"#1341B6"} size={"4"}/></Button>
            </Link>
          </VStack>
          <VStack alignItems={"center"} marginBottom={"6"} textAlign={'center'} paddingTop={'8'}>
            <Text color={"#1341B6"} fontSize={"4xl"} fontFamily={'heading'} lineHeight={'30px'}>Your Garment’s <br/>Provenance</Text>
          </VStack>
          <VStack alignItems={'center'} space={2}>
            <Text color={'#1341B6'} fontSize={'10px'} letterSpacing={'0.46px'}>7,498 km traveled to you</Text>
            <Text color={'#1341B6'} fontSize={'10px'} letterSpacing={'0.46px'}>(avg. is 26,800 km)</Text>
          </VStack>
      <VerticalTimeline lineColor="red" animate='false' layout="1-column-left" className="timeLineCustom2">
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ boxShadow:'none'}}
  contentArrowStyle={{display:'none'}}
  iconStyle={{ background: '#fff', color: 'black', border:'1px solid #1341B6', boxShadow:'none', padding:'3px', height:'60px', width:'60px'}}
  icon={<Image
    source={{ uri: imageOne }}
    height={'full'}
    borderRadius={'50%'}
  />}
>
  <VStack space={4}>
    
    <Text fontFamily={'heading'} color={'#1341B6'} fontSize={'2xl'} letterSpacing={'0.46px'}>Fibre</Text>
    <Text color={'#1341B6'} fontSize={'12px'}>The Swedish wool is sourced from 3 different wool farms, from Findor sheep breed, where the sheep are free to roam and there is minimal waste.  </Text>
    
    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Ekeby GÅrd</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Eskilstuna, Sweden</Text>
    </VStack>

    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>RÖdje farm</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Ydre, Sweden</Text>
    </VStack>

    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>HÄlla farm</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Sundsvall, swedeen</Text>
    </VStack>

    </VStack>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ boxShadow:'none'}}
  contentArrowStyle={{display:'none'}}
  iconStyle={{ background: '#fff', color: 'black', border:'1px solid #1341B6', boxShadow:'none', padding:'3px', height:'60px', width:'60px'}}
  icon={<Image
    source={{ uri: imageTwo }}
    height={'full'}
    borderRadius={'50%'}
  />}
>
  <VStack space={4}>
    
    <Text fontFamily={'heading'} color={'#1341B6'} fontSize={'2xl'} letterSpacing={'0.46px'}>Fibre Processing</Text>
    <Text color={'#1341B6'} fontSize={'12px'}>As there are no longer any places to process the wool in Sweden, where it can be sorted, cleaned and spun, the spinner was carefully selected in Italy. The spinner is a British transport to Italy, who buys the wool directly from our farms.</Text>
    
    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>biella wool consortium</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Biella, italy</Text>
    </VStack>

  </VStack>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ boxShadow:'none'}}
  contentArrowStyle={{display:'none'}}
  iconStyle={{ background: '#fff', color: 'black', border:'1px solid #1341B6', boxShadow:'none', padding:'3px', height:'60px', width:'60px'}}
  icon={<Image
    source={{ uri: imageThree }}
    height={'full'}
    borderRadius={'50%'}
  />}
>
  <VStack space={4}>
    
    <Text fontFamily={'heading'} color={'#1341B6'} fontSize={'2xl'} letterSpacing={'0.46px'}>Fabric Weaver</Text>
    <Text color={'#1341B6'} fontSize={'12px'}>The husband and wife duo behind our wool weaver started with linen and changed to wool in 2014 with their own small mill. </Text>
    
    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>VÄveriet I BollnÄs</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>BÖllnas, Sweden</Text>
    </VStack>

  </VStack>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ boxShadow:'none'}}
  contentArrowStyle={{display:'none'}}
  iconStyle={{ background: '#fff', color: 'black', border:'1px solid #1341B6', boxShadow:'none', padding:'3px', height:'60px', width:'60px'}}
  icon={<Image
    source={{ uri: imageFour }}
    height={'full'}
    borderRadius={'50%'}
  />}
>
  <VStack space={4}>
    
    <Text fontFamily={'heading'} color={'#1341B6'} fontSize={'2xl'} letterSpacing={'0.46px'}>Fabric Dyer & Processer</Text>
    <Text color={'#1341B6'} fontSize={'12px'}>The fourth generation family business, open since 1935, focuses on dyeing and finishing such as felting. After felting to get the right material, the jacket is dyed. The factory runs on renewable energy and recycles its hot air to heat water for dyeing.</Text>
    
    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>sjuhÄradbygdens fÄrgeri</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Kinna, sweden</Text>
    </VStack>

  </VStack>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="timeLineElementLast"
  contentStyle={{ boxShadow:'none'}}
  contentArrowStyle={{display:'none'}}
  iconStyle={{ background: '#fff', color: 'black', border:'1px solid #1341B6', boxShadow:'none', padding:'3px', height:'60px', width:'60px'}}
  icon={<Image
    source={{ uri: imageFive }}
    height={'full'}
    borderRadius={'50%'}
  />}
>
<VStack space={4}>
    
    <Text fontFamily={'heading'} color={'#1341B6'} fontSize={'2xl'} letterSpacing={'0.46px'}>Cutting  and Sewing</Text>
    <Text color={'#1341B6'} fontSize={'12px'}>Your garment was cut and sewn in a small sewing factory started by 3 friends that are dedicated to the details. They believe in transparency and sustainable production.</Text>
    
    <VStack space={2}>
      <Text fontFamily={'bodyBold'} color={'#1341B6'} fontSize={'14px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>Malmö industries</Text>
      <Text color={'#1341B6'} fontSize={'10px'} textTransform={'uppercase'} letterSpacing={'0.46px'}>malmö, sweden</Text>
    </VStack>

  </VStack>
</VerticalTimelineElement>

</VerticalTimeline>
<VStack px={4}>
<Link href="https://www.anewsweden.com/blogs/suppliers" width={'full'}>
          <Button variant="solid" colorScheme="primary" width="full" my={4}>
            Learn More
          </Button>
          </Link>
        </VStack>
    </>
  );
}

export default DressTwoSupplyChain;
